<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      一个戴南高级中学学生的个人网站<br>
      check out the
      <a href="@" target="_blank" rel="noopener">vue-cli documentation</a>.
    </p>
    <h3>关于About</h3>
    <ul>
      <li>2008年</li>
    </ul>
    <h3>学习经历</h3>
    <ul>
      <li>垛田中心小学</li>
      <li>昭阳湖初级中学</li>
      <li>戴南高级中学（在读中）</li>
      <li>前程似锦</li>
    </ul>
    <h3>详细</h3>
    <ul>
      <li>地址：中国兴化</li>
      <li>微信：WX***Y</li>
      <li>Email:235****345@qq.com</li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
