<template>
<svg t="1704806407730" class="icon" viewBox="0 0 1026 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="44699" width="200" height="200"><path d="M930.436548 0H97.961734C45.08239 0 2.199141 42.883249 2.199141 95.762593v832.474814c0 52.879344 42.883249 95.762593 95.762593 95.762593h832.474814c52.879344 0 95.762593-42.883249 95.762593-95.762593V95.762593c0-52.879344-42.883249-95.762593-95.762593-95.762593zM582.27255 745.408825c0 37.585318-30.488091 68.073409-68.073409 68.073409H238.406872c-37.585318 0-68.073409-30.488091-68.073409-68.073409 0-37.585318 30.488091-68.073409 68.073409-68.073409h275.792269c37.585318 0 68.073409 30.488091 68.073409 68.073409z m275.692307 0c0 37.585318-30.488091 68.073409-68.073408 68.073409-37.585318 0-68.073409-30.488091-68.073409-68.073409 0-37.585318 30.488091-68.073409 68.073409-68.073409 37.685279 0 68.073409 30.488091 68.073408 68.073409z m0-258.499024c0 37.585318-30.488091 68.073409-68.073408 68.073409H238.406872c-37.585318 0-68.073409-30.488091-68.073409-68.073409V243.904725c0-37.585318 30.488091-68.073409 68.073409-68.073409 37.585318 0 68.073409 30.488091 68.073409 68.073409v105.158922c0 38.584928 31.287778 69.772745 69.772745 69.772745 38.584928 0 69.772745-31.287778 69.772745-69.772745V243.904725c0-37.585318 30.488091-68.073409 68.073409-68.073409 37.585318 0 68.073409 30.488091 68.073409 68.073409v105.158922c0 38.584928 31.287778 69.772745 69.772745 69.772745 38.584928 0 69.772745-31.287778 69.772745-69.772745V243.904725c0-37.585318 30.488091-68.073409 68.073409-68.073409 37.585318 0 68.073409 30.488091 68.073409 68.073409v243.005076z" p-id="44700" fill="#41b883"></path></svg>  <HelloWorld msg="欢迎进入我的世界"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
